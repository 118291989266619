import {Http} from '@wix/wixstores-client-core/dist/es/src/http/http';
import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {APP_DEFINITION_ID, PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';

export const CHECKOUT_URL = '/_api/wix-ecommerce-renderer-web/store-front/checkout/cart/{cartId}';

export interface CheckoutInfo {
  cartId: string;
  isFastFlow: boolean;
  isPickupFlow: boolean;
  checkoutRelativeUrl: string;
  siteBaseUrl: string;
  thankYouPageUrl: string;
  cartUrl: string;
  paymentMethodName: string;
  locale: string;
  deviceType: string;
  a11y: boolean;
}

export class CheckoutService {
  private readonly siteStore: SiteStore;
  private readonly httpClient: Http;

  constructor(siteStore: SiteStore, httpClient: Http) {
    this.siteStore = siteStore;
    this.httpClient = httpClient;
  }

  private readonly getCheckoutInViewerUrl = (
    relativeUrl: string,
    queryParams: {
      a11y: boolean;
      cartId: string;
      storeUrl: string; //this param is not used in client or server but needed by cashier! dont remove it!
      isFastFlow: boolean;
      isPickupFlow: boolean;
      cashierPaymentId: string;
    }
  ) => {
    return `${relativeUrl}?appSectionParams=${JSON.stringify(queryParams)}`;
  };

  private readonly getCheckoutOutOfViewerUrl = async (
    deviceType: string,
    a11y: boolean,
    queryParams: {
      paymentMethodName: string;
      locale: string;
      cartId: string;
      successUrl: string;
      cancelUrl: string;
      siteBaseUrl: string;
      isFastFlow: boolean;
      isPickupFlow: boolean;
    },
    templateParams: {cartId: string}
  ): Promise<string> => {
    const serverResult = await this.httpClient.get(CHECKOUT_URL, {templateParams, queryParams});
    return `${serverResult.data.url}&deviceType=${deviceType}&a11y=${a11y}`;
  };

  private readonly isEligibleForCheckoutInViewer = async (): Promise<boolean> => {
    const isCheckoutInstalled = await this.siteStore.siteApis.isAppSectionInstalled({
      appDefinitionId: APP_DEFINITION_ID,
      sectionId: PageMap.CHECKOUT,
    });

    const baseUrl = this.siteStore.location.baseUrl;
    const isSslSecured = baseUrl.indexOf('https') > -1;
    return isCheckoutInstalled && isSslSecured;
  };

  private readonly openCheckoutInViewer = async (): Promise<boolean> => {
    return (
      (await this.isEligibleForCheckoutInViewer()) && this.siteStore.experiments.enabled('specs.stores.ExpressCheckout')
    );
  };

  public navigateToCheckout = async (checkoutInfo: CheckoutInfo): Promise<void> => {
    if (await this.openCheckoutInViewer()) {
      this.siteStore.location.to(
        this.getCheckoutInViewerUrl(checkoutInfo.checkoutRelativeUrl, {
          a11y: checkoutInfo.a11y,
          cartId: checkoutInfo.cartId,
          storeUrl: checkoutInfo.siteBaseUrl, //this param is not used in client or server but needed by cashier! dont remove it!
          isFastFlow: checkoutInfo.isFastFlow,
          isPickupFlow: checkoutInfo.isPickupFlow,
          cashierPaymentId: '',
        })
      );
    } else {
      this.siteStore.location.to(
        await this.getCheckoutOutOfViewerUrl(
          checkoutInfo.deviceType,
          checkoutInfo.a11y,
          {
            paymentMethodName: checkoutInfo.paymentMethodName,
            locale: checkoutInfo.locale,
            cartId: checkoutInfo.cartId,
            successUrl: checkoutInfo.thankYouPageUrl,
            cancelUrl: checkoutInfo.cartUrl,
            siteBaseUrl: checkoutInfo.siteBaseUrl,
            isFastFlow: checkoutInfo.isFastFlow,
            isPickupFlow: checkoutInfo.isPickupFlow,
          },
          {cartId: checkoutInfo.cartId}
        )
      );
    }
  };
}
