import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {CartActions} from '@wix/wixstores-client-core/dist/es/src/cart-actions/cartActions';
import {IProductDTO, UserInput, UserInputErrors} from '../types/app-types';
import {
  validateUserInputs,
  formatCustomTextFields,
} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import {getProductBySlug, getDefaultProduct} from './getProduct';
import {PubSubManager} from '@wix/wixstores-client-core/dist/es/src/pub-sub-manager/pubSubManager';
import {PageMap, AddToCartActionOption} from '@wix/wixstores-client-core/dist/es/src/constants';
import {GetProductBySlugQuery, GetFirstProductQuery} from '../graphql/queries-schema';
import {ProductOptionsService} from '@wix/wixstores-client-core/dist/es/src/productOptions/ProductOptionsService';

export class ProductService {
  public options?: ProductOptionsService;
  private readonly cartActions: CartActions;

  private product: IProductDTO;

  constructor(private readonly siteStore: SiteStore, pubSubManager: PubSubManager, onAddToCartSuccess: Function) {
    this.cartActions = new CartActions(this.siteStore, pubSubManager, 'product-page', PageMap.CART, onAddToCartSuccess);
  }

  public updateOptions(product: IProductDTO): void {
    this.product = product;
    this.options = new ProductOptionsService(product);
  }

  public async getProductBySlug(slug: string, externalId: string): Promise<GetProductBySlugQuery> {
    const {data} = await getProductBySlug(this.siteStore, slug, externalId);

    return data;
  }

  public async getDefaultProduct(externalId: string): Promise<GetFirstProductQuery> {
    const {data} = await getDefaultProduct(this.siteStore, externalId);

    return data;
  }

  public validate(userInputs: UserInput): UserInputErrors {
    return validateUserInputs(this.product, userInputs);
  }

  public shouldNavigateToCart(): boolean {
    return this.cartActions.shouldNavigateToCart();
  }

  public addToCart(
    product: IProductDTO,
    userInputs: UserInput,
    addToCartAction: AddToCartActionOption = AddToCartActionOption.MINI_CART
  ): Promise<any> {
    const customTextFields = formatCustomTextFields(product, userInputs);
    const selectionIds = userInputs.selection.map(s => s.id);
    const quantity = userInputs.quantity[0];
    return this.cartActions.addToCart(product.id, selectionIds, quantity, customTextFields, addToCartAction);
  }
}
