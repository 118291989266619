export const VolatileCartApiUrl = '/stores/v1/carts/volatileCart';
export const getStandaloneCartId = async (
  authorization: string,
  productId: string,
  optionSelectionId: number[],
  quantity: number,
  customTextFieldSelection?: {title: string; value: string}[]
): Promise<string> => {
  const query = {
    method: 'post',
    headers: {'Content-Type': 'application/json', Accept: '*/*', Authorization: authorization},
    body: JSON.stringify({
      productId,
      optionSelectionId,
      customTextFieldSelection,
      quantity,
    }),
  };
  const response = await fetch(VolatileCartApiUrl, query);
  const responseBody = await response.json();
  return responseBody.cart.id;
};
